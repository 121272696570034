import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Reasons from "../components/reasons"

const AboutPage = props => {
  const teamMembers = props.data.markdownRemark.frontmatter.teamMembers
  return (
    <Layout>
      <SEO title="Meet the Team" />
      <main className="main-container">
        <div className="title-container">
          <h1 className="page-title">Meet the Team</h1>
        </div>
        <div
          style={{ backgroundColor: "transparent" }}
          className="main-content-container"
        >
          <div className="team-container">
            {teamMembers.teamMember.map(teamMember => {
              return (
                <div className="team-member">
                  <img src={teamMember.image} />
                  <p className="team-member-name">{teamMember.name}</p>
                  <p className="team-member-title">{teamMember.title}</p>
                </div>
              )
            })}
          </div>
        </div>
        <Reasons bottom />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TeamPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Team" } }) {
      frontmatter {
        teamMembers {
          teamMember {
            name
            title
            image
          }
        }
      }
    }
  }
`

export default AboutPage
